.theQuestion {
  text-align: center;
  font-size: 10vw;
}

.start {
  text-align: center;
  font-size: 5vw;
}

.submit-me {
  border: 0px solid green;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  padding: 30px;
}
